import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  useColorMode,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../../components/choc/BannerBlepharo"
import BannerHyaloron from "../../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../../components/choc/QuadTeaser"
import QuadTeaserFacelift from "../../components/choc/QuadTeaserFacelift"
import ScrollcompontentListelement from "../../components/choc/ScrollComponentListElement"
import ImgTextModule from "../../components/ImgTextModule"
import LayoutA from "../../components/layout/LayoutA"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgMaskReveal from "../../DefinitionComponents/Animation-Components/ImgMaskReveal"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"

const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
      title={"Ästhetik: Facelift in Wilhelmshaven | VeoMed"}
      description={
        "Facelift oder Facelifting: operative Straffung von Gesicht, Hals und Stirn. Schönheits-OP's, Behandlungen und Korrekturen: JadeKlinik in Wilhelmshaven."
      }
    >
      <ImgTextModule
        src={"35313.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        rounded="none"
        h="96vh"
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Heading
              color="brand.tan.ulight"
              fontWeight="bold"
              fontSize={{ base: "4xl", lg: "9xl" }}
            >
              Facelift
            </Heading>
          </FadeInAnimation>

          <Flex mt={0}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.5}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink
                isExternal={true}
                href="https://web.online-tis.de/start/M24437Yb6M"
              >
                <Button mt={4} variant="ghost" color="white">
                  Termine online
                </Button>
              </RouterLink>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={0.74}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink isExternal={false} link={"/kontakt/"}>
                <Button
                  ml={4}
                  mt={4}
                  variant="solid"
                  color="brand.violet.primary"
                  _hover={{
                    backgroundColor: "brand.violet.primary",
                    color: "white",
                  }}
                >
                  Kontakt
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Flex>
        </Box>
      </ImgTextModule>
      <LogoHeadlineTeaser
        py={24}
        headline="Was ist ein Facelift?"
        subheadline="Als Facelift oder Facelifting bezeichnet man die operative
        Straffung von Gesicht, Hals und Stirn.
        "
      />
      <ImgMaskReveal
        imgsrc={"andrey-zvyagintsev-T4bFs7q9E94-unsplash.jpg"}
        initScale={1}
        maxH={700}
        duration={1}
        delay={1}
      />
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5} px={32}>
        <Box>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Heading
              color="brand.tan.light"
              fontWeight="bold"
              fontSize={{ base: "5xl", lg: "5xl" }}
            >
              Der Patient / die Patientin kann dabei zwischen verschiedenen
              Methoden und Regionen der Straffung wählen.
            </Heading>
          </FadeInAnimation>
        </Box>
      </Container>
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5} px={32}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Methoden."
          text1="Welche Methoden bieten wir an und welche davon ist für Sie die richtige?"
          text2="
          Vorweg: hier werden einige Indikatoren genannt, die keine Beratung bei dem behandelnden Arzt ersetzen. Sollten Sie Interesse an einer der Varianten haben, laden wir Sie gern zu einem unverbindlichen Beratungstermin ein.
          "
        />
      </Container>
      <QuadTeaserFacelift />
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"35336.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="76vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Wie geht es weiter?
              </Heading>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "3xl" }}
              >
                Wir stehen gern zu einem Beratungs- <br />
                und Informationsgespräch bereit.
              </Heading>
              <Flex mt={0}>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink
                    isExternal={true}
                    href="https://web.online-tis.de/start/M24437Yb6M"
                  >
                    <Button mt={4} variant="ghost" color="white">
                      Termine online
                    </Button>
                  </RouterLink>
                </FadeInAnimation>

                <FadeInAnimation
                  threshold={0.4}
                  delay={0.74}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} link={"/kontakt/"}>
                    <Button
                      ml={4}
                      mt={4}
                      variant="solid"
                      color="brand.violet.primary"
                      _hover={{
                        backgroundColor: "brand.violet.primary",
                        color: "white",
                      }}
                    >
                      Kontakt
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Flex>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />{" "}
      <BannerHyaloron />
      <BannerBlepharo />
      <QuadTeaser />
      <Container variant="layoutContainer" mt={0.5} py={4} mb={0.5}>
        {" "}
        <BannerVeoJadeklinik />
      </Container>
    </LayoutA>
  )
}

export default Blank
